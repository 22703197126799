import { ListPickupPresenter } from "@/app/ui/presenters/ListPickupPresenter";
import { ListPickupMapper } from "@/data/persistences/mappers/ListPickupMapper";
import { container } from "tsyringe";
import { ListPickupEndpoints } from "../../endpoints/hydra/ListPickupEndpoints";
import { ListPickupApiRepository } from "../../repositories/api/ListPickupApiRepository";
import ApiService from "../../services/ApiService";

export class ListPickupComponents {
  public static init() {
    container.register<ListPickupApiRepository>(ListPickupApiRepository, {
      useFactory: d => {
        return new ListPickupApiRepository(
          d.resolve(ApiService),
          d.resolve(ListPickupMapper),
          d.resolve(ListPickupEndpoints)
        );
      }
    });
    container.register<ListPickupMapper>(ListPickupMapper, {
      useClass: ListPickupMapper
    });
    container.register<ListPickupPresenter>(ListPickupPresenter, {
      useFactory: d => {
        return new ListPickupPresenter(d.resolve(ListPickupApiRepository));
      }
    });
  }
}
