import { container } from "tsyringe";
import { PickupScheduleApiRepository } from "../repositories/api/PickupScheduleApiRepository";
import ApiService from "../services/ApiService";
import { PickupScheduleMapper } from "@/data/persistences/mappers/PickupScheduleMapper";
import { PickupScheduleEndpoint } from "../endpoints/horde/PickupScheduleEndpoint";
import { PickupSchedulePresenter } from "@/app/ui/presenters/PickupSchedulePresenter";

export class PickupScheduleComponent {
    public static init() {
        container.register<PickupScheduleApiRepository>(PickupScheduleApiRepository, {
            useFactory: d => {
                return new PickupScheduleApiRepository(
                    d.resolve(ApiService),
                    d.resolve(PickupScheduleMapper),
                    d.resolve(PickupScheduleEndpoint)
                )
            }
        });
        container.register<PickupScheduleMapper>(PickupScheduleMapper, {
            useClass: PickupScheduleMapper
        })
        container.register<PickupSchedulePresenter>(PickupSchedulePresenter, {
            useFactory: d => {
                return new PickupSchedulePresenter(d.resolve(PickupScheduleApiRepository))
            }
        });
    }
}