import { DistrictData } from "@/domain/entities/Location";
import { PaginationV2 } from "@/domain/entities/Pagination";
import {
  CourierVehicle,
  PickupCorporateAddress,
  PickupSchedule,
  PickupScheduleList,
} from "@/domain/entities/PickupSchedule";
import {
  ResponsePayload,
  ResponsePayloadV2,
} from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class PickupScheduleMapper {
  public convertListDataFromApi(res: AxiosResponse): PickupScheduleList {
    const { data } = res;

    return new PickupScheduleList({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records,
      }),
      data: data.data?.length
        ? data.data.map(
            (key: any) =>
              new PickupSchedule({
                pickupScheduleId: key.ps_id,
                pickupScheduleClientParentId: key.ps_client_parent_id,
                pickupScheduleClientId: key.ps_client_id,
                pickupScheduleName: key.ps_name,
                pickupScheduleStart: key.ps_start,
                pickupScheduleEnd: key.ps_end,
                pickupScheduleTime: key.ps_time,
                pickupScheduleCityTimezone: key.ps_city_timezone,
                pickupScheduleIntervalWeek: key.ps_interval_week,
                pickupScheduleRepeatDays: key.ps_repeat_days,
                pickupScheduleTransportType: key.ps_transport_type,
                pickupScheduleServiceCodes: key.ps_service_codes,
                pickupScheduleCreatedAt: key.ps_created_at,
                pickupScheduleUpdatedAt: key.ps_updated_at,
                pickupScheduleStatus: key.ps_status,
                pickupScheduleClientName: key.client_name || "",
              })
          )
        : [],
    });
  }

  public convertListCourierVehicle(res: AxiosResponse): CourierVehicle[] {
    const { data } = res;
    return data.data?.length
      ? data.data.map(
          (key: any) =>
            new CourierVehicle({
              id: key.id,
              vehicleName: key.vehicle_name,
            })
        )
      : [];
  }

  public convertDetailPickupSchedule(res: AxiosResponse): PickupSchedule {
    const {
      data: { data },
    } = res;
    return new PickupSchedule({
      pickupScheduleId: data.ps_id,
      pickupScheduleClientParentId: data.ps_client_parent_id,
      pickupScheduleClientId: data.ps_client_id,
      pickupScheduleName: data.ps_name,
      pickupScheduleStart: data.ps_start,
      pickupScheduleEnd: data.ps_end,
      pickupScheduleTime: data.ps_time,
      pickupScheduleCityTimezone: data.ps_city_timezone,
      pickupScheduleIntervalWeek: data.ps_interval_week,
      pickupScheduleRepeatDays: data.ps_repeat_days,
      pickupScheduleTransportType: data.ps_transport_type,
      pickupScheduleServiceCodes: data.ps_service_codes,
      pickupScheduleCreatedAt: data.ps_created_at,
      pickupScheduleUpdatedAt: data.ps_updated_at,
      pickupScheduleStatus: data.ps_status,
      pickupScheduleClientName: data.client_name,
      pickupScheduleDistrictName: data.district_name,
      pickupScheduleAddress: data.ps_address,
      pickupScheduleGeoloc: data.ps_geoloc,
      pickupScheduleCourierNote: data.ps_courier_note,
      pickupScheduleCreatedBy: data.ps_created_by,
      pickupScheduleUpdatedBy: data.ps_updated_by,
      pickupScheduleEstTotalKoli: data.ps_estimate_total_koli,
      pickupScheduleEstTotalTonase: data.ps_estimate_total_tonase,
      pickupScheduleLastRun: data.ps_last_run,
      pickupScheduleNextRun: data.ps_next_run,
      pickupScheduleCityId: data.ps_city_id,
      pickupScheduleDistrict: data.ps_district_id,
      pickupCorporateAddress: new PickupCorporateAddress({
        pcaId: data.pickup_corporate_address?.pca_id,
        pcaClientId: data.pickup_corporate_address?.pca_client_id,
        pcaIsDefault: data.pickup_corporate_address?.pca_is_default,
        pcaName: data.pickup_corporate_address?.pca_name,
        pcaLatlon: data.pickup_corporate_address?.pca_latlon,
        pcaPhoneNumber: data.pickup_corporate_address?.pca_phone_number,
        pcaAddress: data.pickup_corporate_address?.pca_address,
        pcaPicName: data.pickup_corporate_address?.pca_pic_name,
        pcaPostalCode: data.pickup_corporate_address?.pca_postal_code,
        pcaDistrictId: data.pickup_corporate_address?.pca_district_id,
        pcaDistrictName:
          data.pickup_corporate_address?.pca_district?.name ?? "",
        pcaDistrict: new DistrictData({
          id: data.pickup_corporate_address?.pca_district?.id,
          code: data.pickup_corporate_address?.pca_district?.code,
          name: data.pickup_corporate_address?.pca_district?.name,
        }),
      }),
    });
  }

  public convertUpdateStatusPickupSchedule(
    res: AxiosResponse
  ): ResponsePayloadV2 {
    const {
      data: { data },
    } = res;

    return new ResponsePayloadV2({ data });
  }

  public convertListPickupCorporateAddressDataFromApi(
    res: AxiosResponse
  ): ResponsePayloadV2 {
    const {
      data: { data, meta },
    } = res;

    return new ResponsePayloadV2({
      pagination: new PaginationV2({
        page: meta.page,
        limit: meta.per_page,
        totalData: meta.total_records,
      }),
      data: data?.length
        ? data.map(
            (item: any) =>
              new PickupCorporateAddress({
                pcaId: item.pca_id,
                pcaClientId: item.pca_client_id,
                pcaIsDefault: item.pca_is_default,
                pcaName: item.pca_name,
                pcaLatlon: item.pca_latlon,
                pcaPhoneNumber: item.pca_phone_number,
                pcaAddress: item.pca_address,
                pcaPicName: item.pca_pic_name,
                pcaPostalCode: item.pca_postal_code,
                pcaDistrictId: item.pca_district_id,
                pcaDistrict: new DistrictData({
                  id: item.pca_district?.id,
                  code: item.pca_district?.code,
                  name: item.pca_district?.name,
                }),
              })
          )
        : [],
    });
  }

  public convertAddPickupCorporateAddress(res: AxiosResponse): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data,
    });
  }
}
