import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";

export class PickupFormApiRequest {
  clientId = "";
  clientParentId = "";
  client3lc = "";
  clientRef = "";
  origin = "";
  pickupAddress = "";
  courierNote = "";
  geoloc = "";
  pickupDate = "";
  pickupTime = "";
  transportType = "";
  serviceCode = "";
  estimateTotalKoli = 0;
  estimateTotalTonase = 0;
  pickupType = "";
  clientPhoneNumber = "";
  pickupCorporateAddressId = 0;

  constructor(fields?: Partial<PickupFormApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    const rawData = ConvertObjectCamelToSnakeCase({ ...this });
    rawData["client_3lc"] = this.client3lc;
    const { client3lc, ...pickupRequest } = rawData;
    return JSON.stringify(pickupRequest);
  }
}
