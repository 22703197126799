import { PickupScheduleApiRepository } from "@/app/infrastructures/repositories/api/PickupScheduleApiRepository";
import {
  PickupCorporateAddressRequest,
  PickupScheduleApiRequest,
  PickupScheduleFormApiRequest,
} from "@/data/payload/api/PickupScheduleApiRequest";
import { ApiRequestList } from "@/domain/entities/MainApp";
import {
  CourierVehicle,
  PickupSchedule,
  PickupScheduleList,
  RequestPickupScheduleList,
} from "@/domain/entities/PickupSchedule";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class PickupSchedulePresenter {
  private repository: PickupScheduleApiRepository;
  constructor(repo: PickupScheduleApiRepository) {
    this.repository = repo;
  }

  public getPickupScheduleList(
    params: RequestPickupScheduleList
  ): Promise<PickupScheduleList> {
    return this.repository.getPickupScheduleList(params);
  }
  public getDetailPickupSchedule(id: any): Promise<PickupSchedule> {
    return this.repository.getDetailPickupSchedule(id);
  }
  public getCourierVehicleList(search: string): Promise<CourierVehicle[]> {
    return this.repository.getCourierVehicleList(search);
  }
  public updateStatusPickupSchedule(
    payload: PickupScheduleApiRequest,
    id: any
  ): Promise<ResponsePayloadV2> {
    return this.repository.updateStatusPickupSchedule(payload, id);
  }

  public createPickupSchedule(
    payload: PickupScheduleFormApiRequest
  ): Promise<boolean> {
    return this.repository.createPickupSchedule(payload);
  }

  public updatePickupSchedule(
    payload: PickupScheduleFormApiRequest,
    id: number
  ): Promise<boolean> {
    return this.repository.updatePickupSchedule(payload, id);
  }

  public getListPickupCorporateAddress(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getListPickupCorporateAddress(params);
  }

  public setPickupCorporateAddressDefault(id: number): Promise<boolean> {
    return this.repository.setPickupCorporateAddressDefault(id);
  }

  public deletePickupCorporateAddressDefault(id: number): Promise<boolean> {
    return this.repository.deletePickupCorporateAddressDefault(id);
  }

  public addPickupCorporateAddress(
    payload: PickupCorporateAddressRequest
  ): Promise<ResponsePayload> {
    return this.repository.addPickupCorporateAddress(payload);
  }

  public updatePickupCorporateAddress(
    payload: PickupCorporateAddressRequest,
    id: number
  ): Promise<boolean> {
    return this.repository.updatePickupCorporateAddress(payload, id);
  }
}
