import { PickupScheduleComponent } from "@/app/infrastructures/dependencies/PickupScheduleComponent";
import {
  PickupCorporateAddress,
  PickupSchedule,
  PickupScheduleList,
  RequestPickupScheduleList,
} from "@/domain/entities/PickupSchedule";
import store from "@/store";
import { container } from "tsyringe";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import { PickupSchedulePresenter } from "../presenters/PickupSchedulePresenter";
import {
  PickupCorporateAddressRequest,
  PickupScheduleApiRequest,
  PickupScheduleFormApiRequest,
} from "@/data/payload/api/PickupScheduleApiRequest";
import {
  ApiRequestList,
  ModalMessageEntities,
} from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

interface PickupScheduleState {
  pickupScheduleList: PickupScheduleList;
  pickupScheduleData: PickupSchedule;
  isLoading: boolean;
}
PickupScheduleComponent.init();
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "pickup-schedule",
})
class PickupScheduleStore extends VuexModule implements PickupScheduleState {
  public pickupScheduleList: PickupScheduleList = new PickupScheduleList();
  public pickupScheduleData: PickupSchedule = new PickupSchedule();
  public listPickupCorporateAddress: PickupCorporateAddress[] = [];
  public selectedAddress = new PickupCorporateAddress();
  public isLoading = false;
  public isSuccessAddAddress = false;

  @Action
  public getPickupScheduleList(params: RequestPickupScheduleList) {
    const presenter = container.resolve(PickupSchedulePresenter);
    return presenter.getPickupScheduleList(params);
  }

  @Action
  public getDetailPickupSchedule(id: any) {
    const presenter = container.resolve(PickupSchedulePresenter);
    return presenter.getDetailPickupSchedule(id);
  }

  @Action
  public updateStatusPickupSchedule(params: {
    payload: PickupScheduleApiRequest;
    id: any;
  }) {
    const presenter = container.resolve(PickupSchedulePresenter);
    return presenter.updateStatusPickupSchedule(params.payload, params.id);
  }

  @Action
  public getCourierVehicleList(search: string) {
    const presenter = container.resolve(PickupSchedulePresenter);
    return presenter.getCourierVehicleList(search);
  }

  @Action({ rawError: true })
  public createPickupSchedule(payload: PickupScheduleFormApiRequest) {
    const presenter = container.resolve(PickupSchedulePresenter);

    return presenter.createPickupSchedule(payload);
  }

  @Action({ rawError: true })
  public updatePickupSchedule(params: {
    payload: PickupScheduleFormApiRequest;
    id: number;
  }) {
    const presenter = container.resolve(PickupSchedulePresenter);

    return presenter.updatePickupSchedule(params.payload, params.id);
  }

  @Action
  public async getListPickupCorporateAddress(params: ApiRequestList) {
    try {
      MainAppController.showLoading();

      const { selectAddress, ...rest } = params;

      const presenter = container.resolve(PickupSchedulePresenter);
      const res: ResponsePayloadV2 = await presenter.getListPickupCorporateAddress(
        new ApiRequestList(rest)
      );
      this.setListPickupCorporateAddress(res.data);

      if (selectAddress) {
        const selectedAddress = new PickupCorporateAddress(
          res.data.find((e: PickupCorporateAddress) => e.pcaIsDefault)
        );
        if (selectedAddress.pcaId) {
          this.setSelectedAddressPickupCorporateAddress(selectedAddress);
        } else {
          this.setSelectedAddressPickupCorporateAddress(
            new PickupCorporateAddress()
          );
        }
      } else {
        if (this.selectedAddress.pcaId) {
          const currentSelectedAddress = new PickupCorporateAddress(
            res.data.find(
              (e: PickupCorporateAddress) =>
                e.pcaId === this.selectedAddress.pcaId
            )
          );
          this.setSelectedAddressPickupCorporateAddress(currentSelectedAddress);
        }
      }
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal mengambil data!", () =>
          this.getListPickupCorporateAddress(params)
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  @Action
  public async setPickupCorporateAddressDefault(data: PickupCorporateAddress) {
    MainAppController.showLoading();
    const presenter = container.resolve(PickupSchedulePresenter);
    await presenter
      .setPickupCorporateAddressDefault(data.pcaId)
      .then(() => {
        MainAppController.closeLoading();
        data.pcaIsDefault = true;
        this.setSelectedAddressPickupCorporateAddress(data);
      })
      .catch((error: any) => {
        MainAppController.closeLoading();
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Terjadi kesalahan pada system. Silahkan coba lagi nanti.",
            () => this.setPickupCorporateAddressDefault(data)
          )
        );
      });
  }

  @Action
  public async deletePickupCorporateAddressDefault(
    data: PickupCorporateAddress
  ) {
    MainAppController.showLoading();
    const presenter = container.resolve(PickupSchedulePresenter);
    await presenter
      .deletePickupCorporateAddressDefault(data.pcaId)
      .then(() => {
        MainAppController.closeLoading();
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            title: `"${data.pcaName}" berhasil dihapus`,
            message: `Anda bisa tambahkan ulang alamat yang baru.`,
            textSuccess: "Oke, mengerti",
            onSubmit: () => {
              MainAppController.closeMessageModal();
            },
            image: "badge-success",
            imageWidth: "36",
          })
        );
      })
      .catch((error: any) => {
        MainAppController.closeLoading();
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Terjadi kesalahan pada system. Silahkan coba lagi nanti.",
            () => this.deletePickupCorporateAddressDefault(data)
          )
        );
      });
  }

  @Action
  public async addPickupCorporateAddress(data: PickupCorporateAddressRequest) {
    MainAppController.showLoading();
    const presenter = container.resolve(PickupSchedulePresenter);
    await presenter
      .addPickupCorporateAddress(data)
      .then((res: any) => {
        MainAppController.closeLoading();
        this.setSuccessAddAddress(true);
      })
      .catch((error: any) => {
        MainAppController.closeLoading();
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Terjadi kesalahan pada system. Silahkan coba lagi nanti."
          )
        );
      });
  }

  @Action
  public async updatePickupCorporateAddress(params: {
    data: PickupCorporateAddressRequest;
    id: number;
  }) {
    MainAppController.showLoading();
    const presenter = container.resolve(PickupSchedulePresenter);
    await presenter
      .updatePickupCorporateAddress(params.data, params.id)
      .then((res: any) => {
        MainAppController.closeLoading();
        this.setSuccessAddAddress(true);
      })
      .catch((error: any) => {
        MainAppController.closeLoading();
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Terjadi kesalahan pada system. Silahkan coba lagi nanti."
          )
        );
      });
  }

  @Mutation
  public setIsLoading(bool: boolean): void {
    this.isLoading = bool;
  }

  @Mutation
  public setPickupSchedulData(data: PickupSchedule): void {
    this.pickupScheduleData = data;
  }

  @Mutation
  public setListPickupCorporateAddress(data: PickupCorporateAddress[]): void {
    this.listPickupCorporateAddress = data;
  }

  @Mutation
  public setSelectedAddressPickupCorporateAddress(
    data: PickupCorporateAddress
  ): void {
    this.selectedAddress = data;
  }

  @Mutation
  public setSuccessAddAddress(bool: boolean): void {
    this.isSuccessAddAddress = bool;
  }
}

export const PickupScheduleController = getModule(PickupScheduleStore);
