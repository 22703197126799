import { PickupScheduleRepositoryInterface } from "@/data/persistences/repositories/contracts/PickupScheduleRepositoryInterface";
import {
  RequestPickupScheduleList,
  PickupScheduleList,
  CourierVehicle,
  PickupSchedule,
} from "@/domain/entities/PickupSchedule";
import ApiService from "../../services/ApiService";
import { PickupScheduleMapper } from "@/data/persistences/mappers/PickupScheduleMapper";
import { PickupScheduleEndpoint } from "../../endpoints/horde/PickupScheduleEndpoint";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import {
  PickupCorporateAddressRequest,
  PickupScheduleApiRequest,
  PickupScheduleFormApiRequest,
} from "@/data/payload/api/PickupScheduleApiRequest";
import { ApiRequestList } from "@/domain/entities/MainApp";

export class PickupScheduleApiRepository
  implements PickupScheduleRepositoryInterface {
  private readonly service: ApiService;
  private readonly mapper: PickupScheduleMapper;
  private readonly endpoints: PickupScheduleEndpoint;

  constructor(
    service: ApiService,
    mapper: PickupScheduleMapper,
    endpoints: PickupScheduleEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  async updateStatusPickupSchedule(
    payload: PickupScheduleApiRequest,
    id: any
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.updateStatusPickupSchedule(id),
      undefined,
      payload as PickupScheduleApiRequest
    );
    return this.mapper.convertUpdateStatusPickupSchedule(resp);
  }
  async getDetailPickupSchedule(id: any): Promise<PickupSchedule> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailPickupSchedule(id)
    );
    return this.mapper.convertDetailPickupSchedule(resp);
  }
  async getCourierVehicleList(search: string): Promise<CourierVehicle[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getVehicleCourierList(search)
    );
    return this.mapper.convertListCourierVehicle(resp);
  }
  async getPickupScheduleList(
    params: RequestPickupScheduleList
  ): Promise<PickupScheduleList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPickupScheduleList(params)
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  async createPickupSchedule(
    payload: PickupScheduleFormApiRequest
  ): Promise<boolean> {
    await this.service.invoke(
      "POST",
      this.endpoints.createPickupSchedule(),
      undefined,
      payload
    );

    return true;
  }

  async updatePickupSchedule(
    payload: PickupScheduleFormApiRequest,
    id: number
  ): Promise<boolean> {
    await this.service.invoke(
      "PATCH",
      this.endpoints.updatePickupSchedule(id),
      undefined,
      payload
    );

    return true;
  }

  async getListPickupCorporateAddress(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListPickupCorporateAddress(params)
    );
    return this.mapper.convertListPickupCorporateAddressDataFromApi(resp);
  }

  async setPickupCorporateAddressDefault(id: number): Promise<boolean> {
    await this.service.invoke(
      "PUT",
      this.endpoints.setPickupCorporateAddressDefault(id)
    );

    return true;
  }

  async deletePickupCorporateAddressDefault(id: number): Promise<boolean> {
    await this.service.invoke(
      "DELETE",
      this.endpoints.deletePickupCorporateAddressDefault(id)
    );

    return true;
  }

  async addPickupCorporateAddress(
    payload: PickupCorporateAddressRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.addPickupCorporateAddress(),
      undefined,
      payload
    );

    return this.mapper.convertAddPickupCorporateAddress(resp);
  }

  async updatePickupCorporateAddress(
    payload: PickupCorporateAddressRequest, 
    id: number
  ): Promise<boolean> {
    await this.service.invoke(
      "PUT",
      this.endpoints.updatePickupCorporateAddress(id),
      undefined,
      payload
    );

    return true;
  }
}
