import {
  ListPickup,
  ListPickupList,
  RequestListPickupList,
} from "@/domain/entities/ListPickup";
import store from "@/store";
import { container } from "tsyringe";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import { ListPickupPresenter } from "../presenters/ListPickupPresenter";
import { ListPickupComponents } from "@/app/infrastructures/dependencies/modules/ListPickupComponents";
import { CancelPickupApiRequest } from "@/domain/entities/ListPickupDetail";
import { PickupFormApiRequest } from "@/data/payload/api/PickupApiRequest";

interface ListPickupState {
  listPickupList: ListPickupList;
  listPickupData: ListPickup;
  isLoading: boolean;
}

ListPickupComponents.init();

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "list-pickup",
})
class ListPickupStore extends VuexModule implements ListPickupState {
  public listPickupList: ListPickupList = new ListPickupList();
  public listPickupData: ListPickup = new ListPickup();
  public isLoading = false;

  @Action
  public getListPickupList(params: RequestListPickupList) {
    const presenter = container.resolve(ListPickupPresenter);
    return presenter.getListPickupList(params);
  }

  @Action
  public getListPickupDetail(id: any) {
    const presenter = container.resolve(ListPickupPresenter);
    return presenter.getListPickupDetail(id);
  }

  @Action
  public cancelPickup(payload: CancelPickupApiRequest) {
    const presenter = container.resolve(ListPickupPresenter);
    return presenter.cancelPickup(payload);
  }

  @Action({ rawError: true })
  public createPickup(payload: PickupFormApiRequest) {
    const presenter = container.resolve(ListPickupPresenter);

    return presenter.createPickup(payload);
  }

  @Mutation
  public setIsLoading(bool: boolean): void {
    this.isLoading = bool;
  }

  @Mutation
  public setListPickupData(data: ListPickup): void {
    this.listPickupData = data;
  }
}

export const ListPickupController = getModule(ListPickupStore);
