import { RequestPickupScheduleList } from "@/domain/entities/PickupSchedule";
import { ApiRequestList } from "@/domain/entities/MainApp";

export class PickupScheduleEndpoint {
  getPickupScheduleList(params: RequestPickupScheduleList) {
    return `horde/v1/client/pickup-schedule?${params.toQueryString()}`;
  }
  getVehicleCourierList(search: string) {
    return `/horde/v1/courier_management/vehicle?search=${search}`;
  }
  getDetailPickupSchedule(id: any) {
    return `/horde/v1/client/pickup-schedule/${id}`;
  }
  updateStatusPickupSchedule(id: any) {
    return `/horde/v1/client/pickup-schedule/${id}`;
  }
  createPickupSchedule() {
    return "/horde/v1/client/pickup-schedule";
  }
  updatePickupSchedule(id: number): string {
    return `/horde/v1/client/pickup-schedule/${id}`;
  }

  getListPickupCorporateAddress(params: ApiRequestList) {
    return `/horde/v1/pickup-corporate-address?${params.toQueryString()}`;
  }

  setPickupCorporateAddressDefault(id: number) {
    return `/horde/v1/pickup-corporate-address/${id}/default`;
  }

  deletePickupCorporateAddressDefault(id: number) {
    return `/horde/v1/pickup-corporate-address/${id}`;
  }

  addPickupCorporateAddress() {
    return `/horde/v1/pickup-corporate-address`;
  }

  updatePickupCorporateAddress(id: number) {
    return `/horde/v1/pickup-corporate-address/${id}`;
  }
}
