import { UpdateStatusPickupSchedule } from "@/domain/entities/PickupSchedule";
import { PickupScheduleRequestInterface } from "../contracts/PickupScheduleRequest";
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";

export class PickupScheduleApiRequest
  implements PickupScheduleRequestInterface {
  payload = new UpdateStatusPickupSchedule();
  constructor(fields?: Partial<PickupScheduleApiRequest>) {
    Object.assign(this, fields);
  }
  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
  }
}

export class PickupScheduleFormApiRequest {
  psName = "";
  psClientId = 0;
  psClientParentId = 0;
  psCityId = "";
  psDistrictId = "";
  psAddress = "";
  psGeoloc = "";
  psCourierNote = "";
  psTransportType = "";
  psServiceCodes = "";
  psEstimateTotalKoli = 0;
  psEstimateTotalTonase = 0;
  psStart = "";
  psEnd?: string = "";
  psTime = "";
  psCityTimezone = "";
  psIntervalWeek = 0;
  psRepeatDays = "";
  psPcaId = 0;

  constructor(fields?: Partial<PickupScheduleFormApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase({ ...this }));
  }
}

export class PickupCorporateAddressRequest {
  pcaName = "";
  pcaIsDefault = false;
  pcaClientId = 0;
  pcaDistrictId = 0;
  pcaAddress = "";
  pcaLatlon = "";
  pcaPhoneNumber = "";
  pcaPicName = "";
  pcaPostalCode = "";
  constructor(fields?: Partial<PickupCorporateAddressRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase({ ...this }));
  }
}
